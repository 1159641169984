import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigationType } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Img } from 'react-image';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../../footer/Footer';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LogoConstruction from '../../animations/LogoConstruction/LogoConstruction';
import LevitatingImage from '../../animations/LevitatingImage/LevitatingImage';

import DigitalPlantAvif from '../../images/home/Digital-Plant.avif';
import DigitalPlant from '../../images/home/Digital-Plant.png';

import { default as ServicesImageAvif } from '../../images/home/offers/services.avif';
import { default as ServicesImage } from '../../images/home/offers/services.png';
import { default as ProductsImageAvif } from '../../images/home/offers/products.avif';
import { default as ProductsImage } from '../../images/home/offers/products.png';
import { default as TrainingImageAvif } from '../../images/home/offers/training.avif';
import { default as TrainingImage } from '../../images/home/offers/training.png';

import AfryImage from '../../images/clients/Afry.png'
import ArteliaImage from '../../images/clients/Artelia.png'
import AvevaImage from '../../images/clients/Aveva.svg'
import BilfingerImage from '../../images/clients/Bilfinger.png';
import BoccardImage from '../../images/clients/Boccard.svg';
import EDFImage from '../../images/clients/EDF.svg';
import IneoImage from '../../images/clients/Ineo.png';
import IngenicaImage from '../../images/clients/Ingenica.png';
import OrinoxImage from '../../images/clients/Orinox.png';
import PaulWurthImage from '../../images/clients/PaulWurth.svg';
import SaipemImage from '../../images/clients/Saipem.svg';
import SFCImage from '../../images/clients/SFC.png';
import SBMImage from '../../images/clients/SBM.svg';
import TechnipImage from '../../images/clients/Technip.png';
import VinciImage from '../../images/clients/Vinci.png';

import { default as EngineeringImageAvif } from '../../images/home/values/engineering.avif';
import { default as EngineeringImage } from '../../images/home/values/engineering.png';
import { default as DevelopmentImageAvif } from '../../images/home/values/development.avif';
import { default as DevelopmentImage } from '../../images/home/values/development.png';
import { default as InventivenessImageAvif } from '../../images/home/values/inventiveness.avif';
import { default as InventivenessImage } from '../../images/home/values/inventiveness.png';

import { default as AvevaPartnerBadge } from '../../images/partners/AVEVA-System-Integrator.png';

import { default as BCFNodeImage } from '../../images/products/BCFNode/BCFNode.png';
import { default as ModelSwapImage } from '../../images/products/ModelSwap/ModelSwap.png';

export default function Home() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigationType = useNavigationType();

  const offers = [
    {
      title: t("Home.Offers.Services.Title"),
      description: t("Home.Offers.Services.Description"),
      image: [ServicesImageAvif, ServicesImage],
      link: "services"
    },
    {
      title: t("Home.Offers.Products.Title"),
      description: t("Home.Offers.Products.Description"),
      image: [ProductsImageAvif, ProductsImage],
      link: "products"
    },
    {
      title: t("Home.Offers.Trainings.Title"),
      description: t("Home.Offers.Trainings.Description"),
      image: [TrainingImageAvif, TrainingImage],
      link: "training"
    }
  ];

  const values = [
    {
      title: t("Home.Values.Engineering.Title"),
      description: t("Home.Values.Engineering.Content"),
      image: [EngineeringImageAvif, EngineeringImage],
    },
    {
      title: t("Home.Values.Development.Title"),
      description: t("Home.Values.Development.Content"),
      image: [DevelopmentImageAvif, DevelopmentImage],
    },
    {
      title: t("Home.Values.Inventiveness.Title"),
      description: t("Home.Values.Inventiveness.Content"),
      image: [InventivenessImageAvif, InventivenessImage],
    },
  ];

  const clients = [
    { name: 'Afry', logo: AfryImage },
    { name: 'Artelia', logo: ArteliaImage },
    { name: 'Aveva', logo: AvevaImage },
    { name: 'Bilfinger', logo: BilfingerImage },
    { name: 'Boccard', logo: BoccardImage },
    { name: 'EDF', logo: EDFImage },
    { name: 'Ineo', logo: IneoImage },
    { name: 'Ingenica', logo: IngenicaImage },
    { name: 'Orinox', logo: OrinoxImage },
    { name: 'Paul Wurth', logo: PaulWurthImage },
    { name: 'Saipem', logo: SaipemImage },
    { name: 'SBM', logo: SBMImage },
    { name: 'SFC', logo: SFCImage },
    { name: 'Technip', logo: TechnipImage },
    { name: 'Vinci', logo: VinciImage },
  ];

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  return (
    <ThemeProvider theme={theme}>
      <main>
        <Grid container justifyContent="center">
          {/* Hero section */}
          <Box
            color="secondary.main"
            sx={{ width: '100%', backgroundColor: 'black', height: 'calc(100vh - 54px)', display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'center' }}>
            <Box
              sx={{
                maxWidth: '1920px',
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                justifyContent: 'center',
                height: '100%',
              }}>
              <Box sx={{
                maxWidth: { xs: '100%', lg: '50%' },
                flexShrink: 0, p: 2, m: 1, ml: { xs: 2, md: 6 },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
                <Typography variant="h1" fontWeight={700} sx={{ color: 'white', mb: 2, mt: 1 }}>
                  {t("Home.Title")}
                </Typography>
                <Typography component="h2" variant="h4" sx={{ color: 'white', mb: 2, whiteSpace: 'pre-line' }}>
                  {t("Home.Subtitle")}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large" 
                  sx={{ width: '200px', alignSelf: 'start' }}
                  onClick={() => {
                    window.scrollTo({
                      top: window.innerHeight - 54 + 1,
                      behavior: 'smooth'
                    });
                  }}>
                  {t("Home.Button")}
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, p: 1, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                <Img
                  src={[DigitalPlantAvif, DigitalPlant]}
                  alt="Digital Plant"
                  style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto', objectFit: 'cover', transform: 'scale(1.2)', transformOrigin: 'center' }}
                  width={1024} height={760}
                />
              </Box>
            </Box>
          </Box>

          {/* Presentation Section */}
          <Box sx={{ width: '100%', mb: { md: 4, xs: 2 } }}>
            <Container sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ mx: { md: 10, xs: 5 }, my: { md: 5, xs: 2 } }}>
                <LogoConstruction Mode="Scroll" />
              </Box>
              <Grid container spacing={3} sx={{ mb: 4, alignItems: 'center', px: { md: 0, xs: 2 } }}>
                <Grid item xs={12}>
                  <Typography variant="h2" color="text.primary" sx={{ textAlign: "center", mx: 2, my: 3 }}>
                    {t("Home.PresentationTitle")}
                  </Typography>
                  <Box sx={{ maxWidth: 'md', margin: 'auto' }}>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line', textAlign: { xs: "left", md: "center" } }}>
                      {t("Home.PresentationContent")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/* Services/Products/Training Section */}
          <Box sx={{ width: '100%', maxWidth: "lg" }}>
            <Container sx={{ pt: 2, pb: { md: 12, xs: 4 } }} >
              <Grid container spacing={4} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                {offers.map((offer) => (
                  <Grid item key={offer.title} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: '100%',
                        position: 'relative',
                        '&:hover': {
                          boxShadow: '3',
                        },
                      }}
                    >
                      <CardActionArea sx={{ position: 'relative', height: '100%' }} component={Link} href={offer.link} style={{ textDecoration: 'none' }}>
                        <CardMedia component="div">
                          <Img
                            src={offer.image}
                            alt={offer.title}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            width={1024} height={1024}
                          />
                        </CardMedia>
                        <CardContent sx={{ paddingBottom: '40px' }}>
                          <Typography component="h3" gutterBottom variant="h4" color="text.primary">
                            {offer.title}
                          </Typography>
                          <Typography style={{ whiteSpace: 'pre-line' }}>
                            {offer.description}
                          </Typography>
                        </CardContent>

                        <Box sx={{
                          position: 'absolute',
                          bottom: '0px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          padding: '8px'
                        }}>
                          {offer.link && (
                            <Button size="small">
                              {t("Home.OffersButton")}
                            </Button>
                          )}
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          {/* Products Section */}
          <Box sx={{ width: '100%', backgroundColor: "#fafafc" }}>
            <Container sx={{ pt: 2, pb: 4, px: { xs: 2, md: 4 } }} maxWidth="lg">
              <Grid item sx={{ pb: 0 }}>
                <Typography variant="h2" color="text.primary" align="center" sx={{ mx: 2, my: 3 }}>{t("Home.ProductsTitle")}</Typography>
              </Grid>
              <Container maxWidth="lg" sx={{ mb: 0 }}>
                <Grid container spacing={{ xs: 2, md: 8 }} sx={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 0 }}>

                  <Grid item md={6} sm={12}>
                    <Box sx={{ margin: 'auto' }}>
                      <Typography variant="body1" align="left" sx={{ whiteSpace: 'pre-line' }}>
                        {t("Home.ProductsContent")}
                      </Typography>

                      <Button
                        variant="contained"
                        color="primary"
                        size="large" 
                        href="products"
                        sx={{ alignSelf: 'start', mt: 4 }}>
                        {t("Home.SeeProducts")}
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item md={6} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LevitatingImage imageUrl={BCFNodeImage} alt="BCFNode" ratio='35%' delay='0s' yTranslation={15} duration="6s" />
                    <LevitatingImage imageUrl={ModelSwapImage} alt="ModelSwap" ratio='50%' delay='2s' yTranslation={15} duration="6s" />
                  </Grid>

                </Grid>
              </Container>
            </Container>
          </Box>

          {/* Vision Section */}
          <Box sx={{ width: '100%' }}>
            <Container sx={{ pt: 2, pb: 8, px: 4 }} maxWidth="lg">
              <Grid item sx={{ pb: 4 }}>
                <Typography variant="h2" color="text.primary" align="center" sx={{ mx: 2, my: 3 }}>{t("Home.VisionTitle")}</Typography>
                <Box sx={{ maxWidth: 'md', margin: 'auto' }}>
                  <Typography variant="body1" align="center" sx={{ whiteSpace: 'pre-line', textAlign: { xs: "left", md: "center" } }}>{t("Home.VisionContent")}</Typography>
                </Box>
              </Grid>
              <Grid container spacing={4}>
                {values.map((value) => (
                  <Grid item key={value.title} xs={12} sm={6} md={4}>
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardMedia component="div">
                        <Img
                          src={value.image}
                          alt={value.title}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          width={1024} height={1024} />
                      </CardMedia>
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom component="h3" variant="h4" color="text.primary">
                          {value.title}
                        </Typography>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          {value.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>

          {/* Clients Section */}
          <Box sx={{ width: '100%', py: 2, backgroundColor: "#fafafc" }}>
            <Grid container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>

              <Grid item xs={12} sx={{ m: 4 }}>
                <Typography variant="h2" color="text.primary" sx={{ textAlign: "center", m: 0 }}>
                  {t("Home.ClientsTitle")}
                </Typography>
              </Grid>

              <Container maxWidth="lg" sx={{ mb: 2 }}>
                <Grid container spacing={3} sx={{ justifyContent: 'flex-start', paddingLeft: 3 }}>
                  {clients.map((client, index) => (
                    <Grid item xs={6} md={2.4} key={index}>
                      <Paper elevation={2} sx={{
                        p: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 120,
                        width: '100%'
                      }}>
                        <img
                          src={client.logo}
                          alt={client.name}
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Grid>
          </Box>


          {/* Partners Section */}
          <Box sx={{ width: '100%', backgroundColor: "#fafafc", pb: { xs: 6, md: 2 } }}>
            <Grid container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>

              <Container maxWidth="lg">
                <Grid container spacing={0} sx={{ justifyContent: 'center', alignItems: 'center', paddingLeft: 3 }}>
                  <Grid item lg={4} md={6} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2, mt: { xs: 0, md: 4 } }}>
                    <img
                      src={AvevaPartnerBadge}
                      alt="Aveva Partnership"
                      style={{ maxWidth: '60%', maxHeight: '60%' }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} >
                    <Box sx={{ margin: 'auto' }}>
                      
                      <Typography gutterBottom component="h3" variant="h4" color="text.primary" sx={{ textAlign: "left", m: 0, mb: 2 }}>
                        {t("Home.AvevaPartnershipTitle")}
                      </Typography>

                      <Typography variant="body1" align="left" sx={{ whiteSpace: 'pre-line' }}>
                        {t("Home.AvevaPartnershipContent")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Box>


        </Grid>
        <Footer></Footer>
      </main>
    </ThemeProvider>
  );
}