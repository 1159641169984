import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import { Img } from 'react-image';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../../../footer/Footer';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigationType, useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { getLanguagePrefix } from '../../../translation/LanguageUrlHandler';
import { addUserAction } from '../../../../services/user/UserActions';

import ProductsData from './../Products.json'
import { default as BCFNodeImage } from '../../../images/products/BCFNode/BCFNode.png';
import { default as BCFNodeUIImage } from '../../../images/products/BCFNode/BCFNode-UI.png';
import { default as BCFImage } from '../../../images/products/BCFNode/BCFicon128.png';

function BCFNode() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate()
  const languagePrefix = getLanguagePrefix(location.pathname);
  const productData = ProductsData.find(item => item.key === 'BCFNode');
  const youtubeUrl = productData.youtubeUrl;
  const targetOrigin = window.location.origin.replace("localhost", "app.localhost").replace("ingeloop", "app.ingeloop");
  const targetPage = targetOrigin + "";
  const [isYearly, setIsYearly] = useState(true);

  useEffect(() => {
    if (navigationType === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  const handleSwitchChange = () => {
    setIsYearly(!isYearly);
  };

  const integrations = [
    {
      title: t("BCFNode.Integrations.NewformaKonekt.Title"),
      description: t("BCFNode.Integrations.NewformaKonekt.Content"),
      youtubeUrl: 'https://www.youtube.com/embed/se7W1k881is?si=65wZShJtx4tg7sKl'
    },
    {
      title: t("BCFNode.Integrations.BIMcollab.Title"),
      description: t("BCFNode.Integrations.BIMcollab.Content"),
      youtubeUrl: 'https://www.youtube.com/embed/FDQjDM99doY?si=RAmwnYMimYPchrmo'
    },
    {
      title: t("BCFNode.Integrations.AR.Title"),
      description: t("BCFNode.Integrations.AR.Content"),
      youtubeUrl: 'https://www.youtube.com/embed/PWJgBFY3G48?si=g71HGGf2XYJDsW9g'
    },
  ];

  const tiers = [
    {
      title: t("BCFNode.Tiers.Enterprise.Title"),
      monthlyPrice: '',
      yearlyPrice: '',
      description: [
        t("BCFNode.Tiers.Enterprise.Feature1"),
        t("BCFNode.Tiers.Enterprise.Feature2"),
        t("BCFNode.Tiers.Enterprise.Feature3"),
        t("BCFNode.Tiers.Enterprise.Feature4"),
      ],
      buttonText: t("BCFNode.TiersContact"),
      buttonVariant: 'contained',
      createTrial: false,
      link: '/contact'
    },
  ];

  return (
    <main>
      <Helmet>
        <title>{t("Meta.Products.BCFNode.Title")}</title>
        <meta name="description" content={t("Meta.Products.BCFNode.Description")} />
      </Helmet>

      <Grid container justifyContent="center">
        <Grid container justifyContent="center">
          <Box
            color="secondary.main"
            sx={{ backgroundColor: 'white', height: 'calc(100vh - 54px)', maxWidth: "xl", display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'center' }}>
            <Box sx={{
              maxWidth: { xs: '100%', lg: '50%' },
              flexShrink: 0, p: 2, m: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: { xs: 8, md: 0 } }}>
                <img src={BCFNodeImage} alt="BCF Node" style={{ marginRight: '8px', width: 'auto', height: '80px' }} />
                <Typography
                  variant="h1"
                  color="text.primary"
                  align="center"
                  gutterBottom
                  sx={{ textAlign: 'center', margin: 0, verticalAlign: 'middle' }}>
                  {t("BCFNode.Title")}
                </Typography>
              </Box>
              <Typography component="h2" variant="h5" align="center" color="text.secondary" paragraph sx={{ lineHeight: 1.4 }}>
                {t("BCFNode.Subtitle")}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, p: 2, m: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
              <Card
                sx={{
                  maxWidth: '100%', maxHeight: '100%', objectFit: 'cover',
                  m: { xs: 0, lg: 10 },
                  opacity: 1,
                  transition: 'transform 0.5s ease',
                  boxShadow: '3'
                }}>
                <Box>
                  <img
                    src={BCFNodeUIImage}
                    alt="BCFNode UI"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                  />
                </Box>
              </Card>
            </Box>
          </Box>

          {/* Presentation */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#fafafc' }}>
            <Container maxWidth="xl" sx={{ mt: 6 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ p: [2, 6], pl: [2, 0], pt: [2, 0], order: { xs: 1, md: 1 } }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: 'relative',
                      width: '100%',
                      paddingTop: '56.25%'
                    }}
                  >
                    <iframe
                      title="BCFNode"
                      src={youtubeUrl}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                      }}
                      allowFullScreen
                    ></iframe>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 2 } }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: [2, 3] }}>
                      {t("BCFNode.Presentation1Title")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("BCFNode.Presentation1Content")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* BCF Standard */}
          <Grid item xs={12} sx={{ mt: 2, bgcolor: '#ffffff' }}>
            <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ p: [2, 8] }}>
                    <img src={BCFImage} alt="BCF Standard" />
                    <Typography variant="h4" sx={{ mt: 2, fontWeight: 500 }}>BIM Collaboration Format</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                  <Box textAlign="left">
                    <Typography variant="h2" color="text.primary" sx={{ mb: [2, 3] }}>
                      {t("BCFNode.Presentation2Title")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("BCFNode.Presentation2Content")}
                    </Typography>
                    <Typography variant="h4" sx={{ mb: 0 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("BCFNode.Presentation2SubTitle1")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("BCFNode.Presentation2SubContent1")}
                    </Typography>
                    <Typography variant="h4" sx={{ mb: 0 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("BCFNode.Presentation2SubTitle2")}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }} style={{ whiteSpace: 'pre-line' }}>
                      {t("BCFNode.Presentation2SubContent2")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* Integrations */}
          <Grid item xs={12} sx={{ mt: 4, bgcolor: '#ffffff' }}>
            <Container sx={{ pt: 2, pb: 8, px: 4 }} maxWidth="lg">
              <Typography variant="h2" color="text.primary" sx={{ mb: 2, textAlign: "center" }}>{t("BCFNode.IntegrationsTitle")}</Typography>
              <Typography variant="body1" align="center" sx={{ mb: 4, whiteSpace: 'pre-line' }}>{t("BCFNode.IntegrationsSubtitle")}</Typography>

              <Grid container spacing={4}>
                {integrations.map((integration) => (
                  <Grid item key={integration.title} xs={12} sm={6} md={4}>
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardMedia component="div">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            position: 'relative',
                            width: '100%',
                            paddingTop: '56.25%'
                          }}
                        >
                          <iframe
                            title="BCFNode"
                            src={integration.youtubeUrl}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              border: 'none'
                            }}
                            allowFullScreen
                          ></iframe>
                        </Box>
                      </CardMedia>
                      <CardContent sx={{ flexGrow: 1, mt: 1 }}>
                        <Typography gutterBottom component="h3" variant="h4" color="text.primary">
                          {integration.title}
                        </Typography>
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          {integration.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>

          {/* Pricing */}
          <Grid item xs={12} sx={{ pb: { xs: 4, md: 6 }, bgcolor: '#fafafc' }}>
            <Container disableGutters maxWidth="md" component="main" sx={{ pt: 4, pb: 6 }}>
              <Typography variant="h2" color="text.primary" sx={{ mb: 2, textAlign: "center" }}>{t("BCFNode.TiersTitle")}</Typography>
              <Typography variant="body1" align="center" sx={{ whiteSpace: 'pre-line' }}>{t("BCFNode.TiersSubtitle")}</Typography>

              {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <Typography variant="body1" color="text.primary">{t("Products.Monthly")}</Typography>
                <Switch
                  checked={isYearly}
                  onChange={handleSwitchChange}
                />
                <Typography variant="body1" color="text.primary">{t("Products.Yearly")}</Typography>
              </Box> */}
            </Container>

            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="flex-end" justifyContent="center">
                {tiers.map((tier) => (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    md={6}
                  >
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: 'center', variant: "h3", color: "text.primary" }}
                        subheaderTypographyProps={{
                          align: 'center',
                        }}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? theme.palette.grey[200]
                              : theme.palette.grey[700],
                        }}
                      />
                      <CardContent>
                        <ul style={{ listStyleType: 'none', paddingInlineStart: '0px', textAlign: 'left', width: '100%' }}>
                          {tier.description.map((line) => (
                            <li key={line} >
                              <Typography variant="subtitle1" align="left" sx={{ display: 'flex', alignItems: 'center' }}>
                                <CheckIcon fontSize='small' sx={{ mr: 2 }} />
                                {line}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        <Button
                          href={tier.link}
                          fullWidth
                          variant={tier.buttonVariant}
                          component="button"
                          sx={{ mx: 2, mb: 2, mt: 1 }}>
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </main>
  );
}

export default BCFNode;
